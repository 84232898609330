import api from './api';


class CustomerService {
    async getCustomers() {
        var customer_list = []
        const response = await api.get("/customers");
  
        customer_list = response.data;        
        
        return customer_list;
    }
    async getCustomer(id) {
        var customer_dict = {}
        const response = await api.get("/customers/"+id );
  
        customer_dict = response.data;        
        
        return customer_dict;
    } 
    async getCustomerCredentials(id) {
        var customerCredentials = {}
        const response = await api.get("/customers/"+id+"/credentials");
        customerCredentials = response.data;        
        
        return customerCredentials;
    }     
    async createCustomerCredentials(id) {
        var customer_credentials_dict = {}
        const response = await api.post("/customers/"+id+"/credentials", {});
  
        customer_credentials_dict = response.data;        
        
        return customer_credentials_dict;
    }  
    async saveCustomer(id, name, desc, author_username) {

        var request = {
            "customer_name": name,
            "customer_desc": desc,
            "customer_author":  {      
              "customer_author_name": author_username,
              "customer_author_date": ""+Date.now()+""
            }
          }  

        const response = await api.put("/customers/"+id, request);

        return response.data;;
    }
    async createCustomer(name, email, desc, author_username) {

        var request = {
            "customer_name": name,
            "customer_email": email,
            "customer_desc": desc,
            "customer_author":  {      
              "customer_author_name": author_username,
              "customer_author_date": ""+Date.now()+""
            }
          }  

        const response = await api.post("/customers", request);

        return response.data;;
    }    
    async deleteCustomer(id) {
  
        const response = await api.delete("/customers/"+id);
          
        return response.data;  ;
    }       
}    
  
  export default new CustomerService();
