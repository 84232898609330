<template>
  <CRow>
    <CToaster :autohide="2000">
      <template>           
        <div v-if="showWorkloadCreatedToast">
          <CToast          
          :show.sync="showWorkloadCreatedToast"
          color="success"
          >
            Workload created!
          </CToast>
        </div>   
      </template>         
      <template>           
        <div v-if="showWorkloadErrorToast">
          <CToast          
          :show.sync="showWorkloadErrorToast"
          color="danger"
          >
            Error creating the Workload!
          </CToast>
        </div>   
      </template>               
    </CToaster>       
    <CCol col="6">
      <CCard>
        <CCardHeader>
          Create Workload
        </CCardHeader>
        <CCardBody>
          <p class="text-center" v-show="loading">
            <CSpinner />
          </p >   
          <span v-show="!loading">
          <!-- Workload Region  -->
          <CRow>
            <CCol col="4">          
              <template>              
              <div :class="{ 'invalid': $v.workload.workload_region.$invalid }">

                <label class="typo__label">DoiT Workload Region</label>
                <multiselect 
                  v-model="workload.workload_region"
                  :options="workloadAvailableRegions" 
                  :searchable="true" 
                  :close-on-select="true"   
                  placeholder="Pick a Region"
                  :disabled="inputDisabled"                  
                >                    
                </multiselect>
                <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> Defines in which AWS region the Workload will be created and can be shared to. Can't be changed afterwards!</div></label>
          
              </div>
              </template>                
            </CCol>

          <!-- Select Customer -->
            <CCol col="6">          
              <template>              
              <div :class="{ 'invalid': $v.workloadSelectedCustomer.$invalid }">

                <label class="typo__label">Customer</label>
                <multiselect 
                  v-model="workloadSelectedCustomer"
                  :options="customers" 
                  :searchable="true" 
                  track-by="customer_id"
                  :close-on-select="true"   
                  label="customer_name"               
                  placeholder="Pick or search a customer"                  
                  :disabled="inputDisabled"
                >                    
                </multiselect>
                <label class="typo__label form__label mt-2"> <div style="color:#768192 !important; font-size: 80%"> The customer the workload beloings to.</div></label>
          
              </div>
              </template>              
            </CCol>
          </CRow>          
          <hr>

          <CRow>
          <!-- Workload Lens  -->
            <CCol col="8">          
              <template>              
                <div :class="{ 'invalid': $v.workloadSelectedLens.$invalid }">
                <label class="typo__label">Lenses</label>
                <multiselect 
                  v-model="$v.workloadSelectedLens.$model"                  
                  :options="availableLenses" 
                  track-by="lens_alias"
                  label="lens_name" 
                  :searchable="false" 
                  :close-on-select="false"   
                  placeholder="Select the Lens for the Workload."
                  :multiple="false"
                  :hideSelected="false"
                  :allowEmpty="true"
                  :custom-label="lensSelectCustomLabel"
                />                          
                <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%"> The Lens of the Workload.</div></label>                  
              </div>
              </template>              
            </CCol>
           </CRow>           
          <hr>

          <!-- Workload Name  -->
          <CRow>
            <CCol col="5">                      
              <CInput
                label="Workload Name"
                placeholder="Workload Name"
                v-model="workload.workload_name"
                valid-feedback="Customer name is valid."
                description="Customer facing name which is used on the report"
                :is-valid="!$v.workload.workload_name.$invalid"
                :disabled="inputDisabled"
              >
                <template #prepend-content><CIcon name="cil-user"/></template>
              </CInput>          
            </CCol>

          <!-- Workload Description  -->
            <CCol col="7">             
              <CTextarea
                  label="Workload Description"
                  v-model="workload.workload_desc"
                  :is-valid="!$v.workload.workload_desc.$invalid"  
                  placeholder="Add a brief description about the Workload & the review."
                  />        
            </CCol>
          </CRow>          
          <hr>

          <!-- Workload AWS Regions  -->
          <CRow class="mb-3">
            <CCol col="5">          
              <template  >
              <div :class="{ 'invalid': $v.workloadSelectedRegions.$invalid }">
                <label class="typo__label">Workloads AWS Regions</label>

                <multiselect 
                  v-model="workloadSelectedRegions"
                  :options="availableAwsRegions" 
                  track-by="region_id"
                  label="region_id"
                  group-values="regions"
                  group-label="region_area"
                  :group-select="true"     
                  :searchable="true" 
                  :close-on-select="false"   
                  placeholder="Pick or search AWS Regions"
                  :disabled="inputDisabled"
                  :multiple="true"
                  :hideSelected="true"                  
                >           
                </multiselect>

                <label class="typo__label form__label mt-2" > <div style="color:#768192 !important; font-size: 80%">AWS Region(s) the workload runs in.</div></label>

              </div>
              </template>              
            </CCol>

            <CCol col="7">             
              <CTextarea
                  label="AWS Account IDs (optional)"
                  v-model="workload.workload_account_ids"
                  :is-valid="!$v.workload.workload_account_ids.$invalid"  
                  placeholder="Add AWS Account IDs seperated by a comma, e.g. 123456789,123456789"
                  description="AWS Accounts the workloads run in."
                  />        
            </CCol>
          </CRow> 
          
          <CRow>
            <CCol col="6">          
              <ASelect
                label="Workload Environment"
                :options="workloadAvailableEnvironments"
                :value.sync="workload.workload_env"
                :disabled="inputDisabled"
                :is-valid="!$v.workload.workload_env.$invalid"
                placeholder="Please select the environment."
                description="The environment of the Workload."
                >
                <template #prepend-content><CIcon name="cil-globe-alt"/></template>
                
              </ASelect>              
            </CCol>

          </CRow>          
          </span>
        </CCardBody>
        <CCardFooter>
          <CButton color="light" class="col-sm-2" @click="goBack">Back</CButton>
          <CButton v-on:click="openWorkload();" color="success" class="float-right col-sm-4" v-show="showOpenWorkload" >Open Workload</CButton>          
          <CButton v-on:click="saveWorkload();" color="success"  class="float-right col-sm-4" :disabled="$v.$invalid || inputDisabled" v-show="!showOpenWorkload">Create Workload</CButton>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>



<script>
import ASelect from '@/components/forms/ASelect.vue'
import Multiselect from 'vue-multiselect'

import LensService from '@/services/lense.services';

import WorkloadService from '@/services/workload.services';
import CustomerService from '@/services/customer.services';

import { required, email, minLength, maxLength, helpers } from 'vuelidate/lib/validators';
const alphaNumAndDotValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9-_\\.&!#=()\\s])*$/i);
const customerDescValidator = helpers.regex('alphaNumAndDot', /^([a-zA-Z0-9- _\,.&!#=\\\/()\s]){0,}$/i);
const awsAccountIdsValidator = helpers.regex('alphaNumAndDot', /^(?:[0-9]{12},?)*$/i);

export default {
  components: {
    ASelect,
    Multiselect
  },
  data() {
    return {
      workload: {
        workload_region: '',
        workload_regions: [],
        workload_name: '',
        customer_id: '',
        workload_desc: '',
        workload_account_ids: [],
        workload_lens: '',
      },
      customers: [],
      workloadSelectedCustomer: '',
      workloadSelectedRegions: [],
      workloadSelectedLens: '',
      availableLenses: [],
      availableAwsRegions: [],
      workloadAvailableRegions: [ "eu-central-1", "us-east-1", "ap-southeast-1" ],
      workloadAvailableEnvironments: { 'PRODUCTION': 'Production', 'PREPRODUCTION': 'Pre-Production' },
      showWorkloadCreatedToast: false,
      showWorkloadErrorToast: false,
      showOpenWorkload: false,
      inputDisabled: false,
      loading: true,
    };
  },
  setup: () => ({ v$: useVuelidate() }),    
  validations: {
    workloadSelectedCustomer: {
      required,
    },
    workloadSelectedRegions: {
      required
    },
    workloadSelectedLens: {
      required
    },
    workload: {
      workload_name: {
        minLength: minLength(4),
        maxLength: maxLength(32),
        required,
        alphaNumAndDotValidator,
      },
      workload_desc: {
        minLength: minLength(3),
        maxLength: maxLength(2048),        
        customerDescValidator,
        required,
      },
      workload_account_ids: {
        awsAccountIdsValidator
      },
      workload_region: {
        required
      },
      workload_env: {
        required,
      }

    }
  },

  methods: {
    goBack() {
      this.$router.push({path: '/workloads'})
    },
    openWorkload() {
      this.$router.push({path: '/workloads/'+this.showOpenWorkloadId})
    },  
    lensSelectCustomLabel(lens){
      return `${lens.lens_name} [${lens.owner}]`
    },
    async saveWorkload() {
      for (var key in this.workloadSelectedRegions) {
        this.workload.workload_regions.push(this.workloadSelectedRegions[key].region_id)
      }             
      this.workload.workload_lens = this.workloadSelectedLens.lens_alias;
      this.workload.customer_id = this.workloadSelectedCustomer.customer_id;
      
      let tmpWorkloadAccountIds = []

      if (this.workload.workload_account_ids !== undefined) {
        tmpWorkloadAccountIds = this.workload.workload_account_ids.split(',')
      }

      WorkloadService.createWorkload(
        this.workload.customer_id, 
        this.workload.workload_env, 
        this.workload.workload_name, 
        this.workload.workload_desc, 
        tmpWorkloadAccountIds, 
        this.workload.workload_regions, 
        this.workload.workload_region, 
        ).then(
          response => { 
            this.showWorkloadCreatedToast = true;
            this.inputDisabled = true;
            this.showOpenWorkload = true;
            this.showOpenWorkloadId = response.workload_id;
          },
          error => {   
            this.showWorkloadErrorToast = true;
            console.log(error);
          }      
      )      

    },   
  },
  created() { },
  async mounted() { 

    await LensService.getLenses().then(
      response => { 
        this.availableLenses = response.lens_summaries;
      },
      error => {   
        console.log(error)   
      }      
    );
    await WorkloadService.getWorkloadsRegionsAws(false).then(
      response => { 
        for (var key in response){          
          var tmp = {}          
          tmp["region_area"] = key
          tmp['regions'] = response[key]        
          this.availableAwsRegions.push(tmp)
        }
      },
      error => {   
        console.log(error)   
      }      
    );    
    CustomerService.getCustomers().then(
      response => { 
        this.customers = response;
        this.loadingCustomersTable = false;
      },
      error => {   
        console.log(error)   
      }      
    ) 
    this.loading = false;
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
